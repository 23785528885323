var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-app-bar',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],staticClass:"fixed-bar",style:(({ boxShadow: _vm.$store.getters['isHomeTop'] ? 'unset' : '' },
      _vm.header_background)),attrs:{"dark":"","height":"80px","clipped-left":_vm.clipped,"app":""}},[_c('PublicIdentificadorTop'),_vm._v(" "),_c('div',{staticClass:"header-top py-1 px-6 px-xl-3"},[_c('v-app-bar-nav-icon',{directives:[{name:"show",rawName:"v-show",value:(
          (_vm.$store.getters['loggedIn'] &&
            _vm.windowSize.x > 960 &&
            _vm.typeProfile === 'Funcionario') ||
          !(_vm.windowSize.x > 960)
        ),expression:"\n          ($store.getters['loggedIn'] &&\n            windowSize.x > 960 &&\n            typeProfile === 'Funcionario') ||\n          !(windowSize.x > 960)\n        "}],style:({
          color: _vm.$store.getters['brand/getHeaderColor']
            ? _vm.$store.getters['brand/getHeaderColor']
            : '#ffffff',
        }),attrs:{"data-cy":"drawerSidebar"},on:{"click":function($event){return _vm.$emit('setDrawer', !_vm.drawerSidebar)}}}),_vm._v(" "),_c('v-col',{staticClass:"d-flex pl-0",class:{
          'justify-start': _vm.$vuetify.breakpoint.mdAndUp,
          'justify-center': !_vm.$vuetify.breakpoint.smAndDown && _vm.user,
        },attrs:{"md":_vm.$vuetify.breakpoint.mdAndUp ? '2' : '6'}},[_c('nuxt-link',{staticClass:"link-image",attrs:{"to":"/","data-cy":"goToHome"}},[_c('PublicLogo',{attrs:{"height-image":_vm.$store.getters['brand/getHeightLogo']
                ? _vm.$store.getters['brand/getHeightLogo']
                : '38px',"image-url":_vm.$store.getters['brand/logo']}})],1)],1),_vm._v(" "),(_vm.windowSize.x > 960)?_c('v-col',{staticClass:"d-flex justify-end align-center",attrs:{"md":_vm.$store.getters['loggedIn']
            ? _vm.$store.getters['setting/hideConnectWallet'] !== true
              ? _vm.typeProfile === 'Funcionario' ||
                _vm.userAuxRole === 'institutional'
                ? 9
                : !_vm.user.public_address
                ? 8
                : 9
              : 9
            : 10,"lg":_vm.$store.getters['loggedIn']
            ? _vm.$store.getters['setting/hideConnectWallet'] !== true
              ? _vm.typeProfile === 'Funcionario' ||
                _vm.userAuxRole === 'institutional'
                ? 9
                : !_vm.user.public_address
                ? 8
                : 10
              : 9
            : 10,"xl":_vm.$store.getters['loggedIn']
            ? _vm.$store.getters['setting/hideConnectWallet'] !== true
              ? _vm.typeProfile === 'Funcionario' ||
                _vm.userAuxRole === 'institutional'
                ? 9
                : !_vm.user.public_address
                ? 9
                : 10
              : 9
            : 10,"sm":"11"}},[(_vm.brand.needsInfo)?[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isInRegister),expression:"!isInRegister"}],staticClass:"mr-2",style:({
              borderRight:
                _vm.$store.getters['setting/hideRegisterLoginHeader'] !== true
                  ? '1px solid'
                  : '',
              borderColor: _vm.$store.getters['brand/getHeaderColor']
                ? _vm.$store.getters['brand/getHeaderColor']
                : 'white',
            })},[(_vm.$store.getters['setting/hideInicioHeader'] !== true)?_c('v-btn',{staticClass:"text-wrap d-inline-flex align-center",attrs:{"plain":"","depressed":"","small":"","text":"","color":_vm.$store.getters['brand/getHeaderColor']
                  ? _vm.$store.getters['brand/getHeaderColor']
                  : '#ffffff',"to":"/"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-home-city-outline")]),_vm._v("\n              "+_vm._s(_vm.$t('home'))+"\n            ")],1):_vm._e(),_vm._v(" "),(_vm.$store.getters['setting/hideWhatIsHeader'] !== true)?_c('v-btn',{staticClass:"text-wrap d-inline-flex align-center",attrs:{"plain":"","depressed":"","small":"","text":"","color":_vm.$store.getters['brand/getHeaderColor']
                  ? _vm.$store.getters['brand/getHeaderColor']
                  : '#ffffff',"to":"/what-is"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-question-outline")]),_vm._v("\n              "+_vm._s(_vm.$t('what-is'))+" "+_vm._s(_vm.brand.app_name)+"?\n            ")],1):_vm._e(),_vm._v(" "),(_vm.$store.getters['setting/hideCitizenHeader'] !== true)?_c('v-btn',{staticClass:"text-wrap d-inline-flex align-center",attrs:{"plain":"","depressed":"","small":"","text":"","color":_vm.$store.getters['brand/getHeaderColor']
                  ? _vm.$store.getters['brand/getHeaderColor']
                  : '#ffffff',"to":"/digital-citizen"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-fingerprint")]),_vm._v("\n              "+_vm._s(_vm.$t('who-digital-citizen'))+"\n            ")],1):_vm._e(),_vm._v(" "),(_vm.activeModules.length > 0)?[_c('v-btn',{staticClass:"text-wrap d-inline-flex align-center",attrs:{"plain":"","data-cy":"digitalServices","depressed":"","small":"","text":"","color":_vm.$store.getters['brand/getHeaderColor']
                    ? _vm.$store.getters['brand/getHeaderColor']
                    : '#ffffff'},on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({ path: '/digital-services' })}}},[_vm._v("\n                "+_vm._s(_vm.$t('digital-services'))+"\n              ")]),_vm._v(" "),_c('v-btn',{staticClass:"text-wrap d-inline-flex align-center",attrs:{"plain":"","depressed":"","small":"","text":"","color":_vm.$store.getters['brand/getHeaderColor']
                    ? _vm.$store.getters['brand/getHeaderColor']
                    : '#ffffff',"to":"/user-guide"}},[_vm._v("\n                Guía de uso\n              ")])]:_vm._e()],2)]:_vm._e(),_vm._v(" "),(_vm.$store.getters['loggedIn'] && _vm.typeProfile !== 'Funcionario')?_c('v-col',{staticClass:"d-flex justify-start",class:{
            'py-0': _vm.$store.getters['loggedIn'],
            'mr-10': _vm.windowSize.x <= 1300 && _vm.user.public_address,
            'mr-5': _vm.windowSize.x > 1300 && _vm.user.public_address,
            'px-0': _vm.userAuxRole === 'institutional',
          },attrs:{"cols":"6","sm":"5","md":_vm.windowSize.x > 1300 ? (_vm.user.public_address ? 5 : 3) : 4,"lg":"5","xl":_vm.userAuxRole === 'institutional' ? 2 : 4}},[_c('div',{attrs:{"id":"login-border"}}),_vm._v(" "),(_vm.user.company)?[_c('CompaniesAvatarMenuCompanies',{attrs:{"with-padding":false}})]:[_c('PublicAvatarMenu',{attrs:{"with-padding":false,"data-cy":'avatarMenuHeader',"type-profile":_vm.typeProfile}})],_vm._v(" "),(_vm.user.public_address && _vm.$store.getters['authconfig/isDID'])?_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isInRegister),expression:"!isInRegister"}],attrs:{"align":"center","justify":"center","data-cy":"identityCitizen"}},[_c('v-col',{attrs:{"align":"left"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"block":"","text":"","color":_vm.$store.getters['brand/getHeaderColor']
                        ? _vm.$store.getters['brand/getHeaderColor']
                        : '#ffffff'},on:{"click":function($event){_vm.copyAdressToClipBoard()
                      _vm.show = !_vm.show}}},'v-btn',attrs,false),on),[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"start"}},[_c('v-col',{staticClass:"ma-0 pa-0"},[_c('p',{staticClass:"ma-0 pa-0 text-left llave-publica"},[_vm._v("\n                            ID Digital\n                          ")]),_vm._v(" "),_c('p',{staticClass:"ma-0 pa-0 text-left llave-publica-numbers"},[_vm._v("\n                            "+_vm._s(_vm.user.public_address.slice(0, 4))+"..."+_vm._s(_vm.user.public_address.slice(-4))+"\n                          ")])]),_vm._v(" "),_c('v-icon',{staticClass:"ml-1"},[_vm._v(" mdi-content-copy ")])],1)],1)],1)]}}],null,false,2453810455),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('copy')))])])],1)],1):(
              !_vm.user.public_address &&
              _vm.$store.getters['authconfig/isDID'] &&
              _vm.$store.getters['setting/hideConnectWallet'] !== true &&
              _vm.userAuxRole === 'citizen'
            )?_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isInRegister && _vm.$store.getters['authconfig/isDID']),expression:"!isInRegister && $store.getters['authconfig/isDID']"}],attrs:{"data-cy":"connectWallet","align":"center","justify":"center"}}):_vm._e()],2):_vm._e(),_vm._v(" "),(
            !_vm.$store.getters['loggedIn'] &&
            (!_vm.$store.getters['authconfig/autenticar'] ||
              _vm.$store.getters['authconfig/register'])
          )?[(_vm.$store.getters['authconfig/getsso'])?[_c('v-btn',{staticClass:"text-capitalize mx-3",attrs:{"id":"login","plain":"","small":"","color":_vm.$store.getters['brand/getHeaderColor']
                  ? _vm.$store.getters['brand/getHeaderColor']
                  : '#ffffff'},on:{"click":function($event){return _vm.signInSSO()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-outline")]),_vm._v("\n              "+_vm._s(_vm.$t('header.login'))+"\n            ")],1),_vm._v(" "),_c('v-btn',{staticClass:"text-capitalize mx-3",attrs:{"id":"login","plain":"","small":"","color":_vm.$store.getters['brand/getHeaderColor']
                  ? _vm.$store.getters['brand/getHeaderColor']
                  : '#ffffff',"to":"/auth/register"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-outline")]),_vm._v("\n              "+_vm._s(_vm.$t('header.register'))+"\n            ")],1)]:[(
                _vm.$store.getters['authconfig/register'] &&
                _vm.$store.getters['setting/hideRegisterLoginHeader'] !== true
              )?_c('v-btn',{staticClass:"text-capitalize mx-3",attrs:{"id":"signup","data-cy":"registerLoginBtn","plain":"","small":"","text":"","color":_vm.$store.getters['brand/getHeaderColor']
                  ? _vm.$store.getters['brand/getHeaderColor']
                  : '#ffffff',"to":"/auth/login"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-outline")]),_vm._v("\n              "+_vm._s(_vm.$t('login-register'))+"\n            ")],1):_vm._e(),_vm._v(" "),(
                !_vm.$store.getters['authconfig/register'] &&
                _vm.$store.getters['authconfig/email'] &&
                _vm.$store.getters['setting/hideRegisterLoginHeader'] !== true
              )?_c('v-btn',{staticClass:"text-capitalize mx-3",attrs:{"id":"login","plain":"","small":"","color":_vm.$store.getters['brand/getHeaderColor']
                  ? _vm.$store.getters['brand/getHeaderColor']
                  : '#ffffff',"to":"/login"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-outline")]),_vm._v("\n              "+_vm._s(_vm.$t('header.login'))+"\n            ")],1):_vm._e()]]:_vm._e(),_vm._v(" "),(
            !_vm.$store.getters['loggedIn'] &&
            _vm.$store.getters['authconfig/isDID'] &&
            _vm.$store.getters['setting/hideConnectWallet'] !== true
          )?_c('v-btn',{staticClass:"text-capitalize mr-3 pa-2",attrs:{"id":"walletConnect","small":"","to":"/auth/login","color":"var(--v-btnPrimary-base)"}},[_vm._v("\n          "+_vm._s(_vm.$t('conectar-wallet'))+"\n        ")]):_vm._e()],2):_vm._e(),_vm._v(" "),(
          _vm.user &&
          (_vm.user.role === 'superadmin' || _vm.user.role === 'communicator') &&
          _vm.$store.getters['loggedIn']
        )?_c('v-btn',{staticClass:"mx-2",attrs:{"id":"btnDialogHeader","data-cy":"btnDialogHeader","absolute":"","right":"","fab":"","dark":"","small":"","color":"primary"},on:{"click":_vm.toggleEditor}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-pencil")])],1):_vm._e()],1),_vm._v(" "),(_vm.typeProfile !== 'Funcionario')?_c('div',[_c('v-alert',{staticClass:"bg-banner",attrs:{"text":"","dense":"","icon":"mdi-information-outline","close-icon":"mdi-close"}},[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","sm":"9","md":"10","lg":"10","xl":"8"}},[_c('p',{staticClass:"mb-0"},[_vm._v("\n              Versión de prueba con sectores específicos. Si tienes acceso y\n              necesitas ayuda, contacta al\n            ")]),_vm._v(" "),_c('div',{staticClass:"ml-2"},[_c('IconsAlertSoporteIcon',{attrs:{"width":_vm.$vuetify.breakpoint.smAndDown ? '50px' : '62px',"height":"32"}})],1)])],1)],1)],1):_vm._e()],1),_vm._v(" "),_c('v-snackbar',{attrs:{"top":true,"right":true,"timeout":_vm.snackbar.timeout,"color":_vm.snackbar.color,"multi-line":_vm.snackbar.mode === 'multi-line',"vertical":_vm.snackbar.mode === 'vertical'},model:{value:(_vm.snackbar.show),callback:function ($$v) {_vm.$set(_vm.snackbar, "show", $$v)},expression:"snackbar.show"}},[_vm._v("\n    "+_vm._s(_vm.snackbar.text)+"\n    "),_c('v-btn',{staticClass:"float-right",attrs:{"icon":"","justify":"center","align":"center"},nativeOn:{"click":function($event){_vm.snackbar.show = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_vm._v(" "),_c('ExpedienteConfirmLevelUpDialog',{attrs:{"dialog-back-afip":_vm.dialogBackAfip}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }